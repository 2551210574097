.root {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 2000;
  background-color: #161616;
  top: 0;
  left: 0;
}

.bg {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-image: url('./Loading-Tile.gif');
  background-size: 40px;
  background-repeat: repeat;
  background-position: center;
  z-index: 2100;
}

.logo {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url('./logo.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 2200;
}

.loading {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  bottom: 50%;
  z-index: 2101;
  font-weight: bold;
  color: var(--grey3);
  display: flex;
  align-content: center;
  line-height: 1;
  transform: translate(-50%, -50%);
}

.loading:before {
  content: '';
  height: 1.1rem;
  margin-right: 1em;
  width: 2em;
  display: inline-block;
  background-image: url('./loading.gif');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.loadingDown {
  position: fixed;
  left: var(--gutter-sm);
  bottom: calc(var(--gutter-sm) * 1.25);
  transform: none;

  @media (--phone-medium-up) {
    bottom: var(--gutter);
  }
  @media (--tablet-up) {
    bottom: calc(var(--gutter-sm) * 1.25);
  }
  @media (--desktop-up) {
    left: var(--gutter);
    bottom: calc(var(--gutter) * 1.25);
  }
}
