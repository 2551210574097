.root {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3000;
  width: 100%;
  text-transform: none;
}

.row {
  display: flex;
  flex-direction: column;
  background: var(--primary);
  padding: var(--gutter-sm);
  padding-bottom: var(--gutter);

  @media (--tablet-up) {
    padding-bottom: calc(var(--gutter-sm) + 5px);
  }

  @media (--desktop-up) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: var(--gutter);
  }
}

.text {
  color: var(--bg-color);
  font-size: 16px;
  @media (--desktop-up) {
    font-size: 12px;
  }
}

.text a {
  color: var(--bg-color);
  text-decoration: underline;
}

.btns {
  display: flex;
  white-space: nowrap;
  align-items: center;
  margin-top: var(--gap-16);

  @media (--desktop-up) {
    margin-top: 0;
    margin-left: var(--gutter);
  }
}

.btns .btn {
  width: 50%;
  padding-left: var(--gap-10);
  padding-right: var(--gap-10);
  flex-grow: 1;
  text-transform: uppercase;

  @media (--desktop-up) {
    width: auto;
    min-width: 175px;
    font-size: 12px;
    line-height: 16px;
    padding: calc(var(--gap-10) * 1.3) calc(var(--gap-10) * 1.3);
  }
}

.btn + .btn {
  margin-left: calc(var(--gutter-sm) / 2);
}
