.root {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 calc(var(--gutter-sm) * -0.5);
  color: var(--color-white);

  @media (--desktop-up) {
    margin: 0 calc(var(--gutter) * -0.5);
  }
}

.root:after {
  content: '';
  padding-bottom: 100%;
}

.slider {
  position: absolute !important;
  width: 100%;
  height: 100%;
}

.img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  object-position: center;
}

.video {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
}

.sizes {
  font-weight: bold;
  margin-bottom: var(--gap-2);
  font-size: 10px;

  @media (--desktop-up) {
    margin-bottom: var(--gap-3);
    font-size: 16px;
  }

  @media (--large-desktop-up) {
    margin-bottom: var(--gap-1);
  }
}

.sizesPreorder {
  color: var(--primary);
}

.body {
  text-align: center;
  position: absolute;
  bottom: var(--gap-40);
  width: 100%;
  z-index: 1;

  @media (--desktop-up) {
    bottom: var(--gap-50);
  }

  @media (--large-desktop-up) {
    bottom: var(--gap-30);
  }
}

.price {
  font-weight: bold;
  font-size: 10px;

  @media (--desktop-up) {
    font-size: 16px;
  }
}

.title {
  margin-bottom: var(--gap-2);
  font-size: 20px;
  line-height: 1.2;

  @media (--desktop-up) {
    font-size: 30px;
    margin-bottom: var(--gap-3);
  }
}

.size {
  margin: 0 var(--gap-6);
  font-size: 10px;
  color: var(--grey);

  @media (--desktop-up) {
    margin: 0 7.5px;
    font-size: 16px;
  }
}

.sizeActive {
  color: var(--primary);
}

.videoItem,
.imageItem {
  position: absolute;
  width: 100%;
  height: 100%;
}

.videoItem .video,
.videoItem :global(.lazyload-loader) {
  display: none;
}

.root:hover .videoItem .video,
.root:hover .videoItem :global(.lazyload-loader) {
  display: flex;
}

.root:hover .imageItem .img {
  display: none;
}