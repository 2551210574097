.root {
  font-family: inherit;
  font-weight: bold;
  background: none;
  border: 0;
  padding: 0;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  text-align: center;

  @media (--desktop-up) {
    font-size: 16px;
    line-height: 21px;
  }
}

.none {
  padding: 0;
}

.link {
  color: var(--primary);
}

.primary,
.secondary,
.inverse,
.dark {
  padding: calc(var(--gap-10) * 1.3) var(--gap-20);

  @media (--desktop-up) {
    padding: calc(var(--gap-16) * 1.1) var(--gap-20);
  }
}

.primary {
  box-shadow: inset 0 0 0 2px var(--primary);
  color: var(--primary);
}

.secondary {
  box-shadow: inset 0 0 0 2px var(--grey3);
  color: var(--grey3);
}

.inverse {
  box-shadow: inset 0 0 0 2px var(--color-black);
  color: var(--color-black);
}

.dark {
  background-color: var(--color-black);
  color: var(--primary);
}

.dash {
  font-weight: bold;
  line-height: 1.2;
  color: var(--color-white);
  border-bottom: 1px solid;
}

.main {
  background-color: var(--primary);
  color: var(--color-white);
  font-weight: bold;
  padding: calc(var(--gap-10) * 1.3) calc(var(--gap-20) * 1.2);

  @media (--desktop-up) {
    padding: calc(var(--gap-16) * 1.1) var(--gap-30);
  }
}

.disabled {
  pointer-events: none;
  background: var(--grey);
}

.primary.disabled {
  background: none;
  box-shadow: inset 0 0 0 2px var(--grey3);
  color: var(--grey3);
}

.full {
  width: 100%;
}
