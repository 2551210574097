.form {
  display: flex;
  flex-direction: column;
}

.fields {
  margin-top: 20px;
  margin-bottom: 40px;

  @media (--tablet-up) {
    margin-top: 15px;
  }

  @media (--desktop-up) {
    margin-top: 30px;
    margin-bottom: 60px;
  }
}

.fields:last-of-type {
  margin-bottom: 0;
}

.fields + .fields {
  margin-top: 0;
}


.title {
  margin-top: 0;
  margin-bottom: 42px;
  font-size: 16px;
  line-height: 14px;
  color: var(--color-white);

  @media (--tablet-up) {
    margin-bottom: 36px;
  }

  @media (--desktop-up) {
    margin-bottom: 64px;
    font-size: 25px;
    line-height: 34px;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: -5px;
}

.link,
.checkbox {
  margin-bottom: var(--gap-6);
  color: var(--grey);
  font-size: 10px;
  font-weight: 700;

  @media (--desktop-up) {
    font-size: 12px;
  }
}

.checkboxInput {
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  opacity: 0;
  visibility: hidden;
}

.checkboxInput:checked + .checkboxLabel {
  color: var(--color-white);
  border-color: var(--color-white);
}

.checkboxInput:checked + .checkboxLabel::before {
  content: '\2713';
}

.checkboxLabel {
  cursor: pointer;
  border-bottom: 1px dashed var(--grey);
}

.checkboxLabel::before {
  content: '\2715';
  padding-right: var(--gap-6);
}

.estimated {
  width: 100%;
  margin-bottom: var(--gap-35);
}

.btn,
.btnMain,
.btnApplePay {
  margin-bottom: var(--gap-50);

  @media (--desktop-up) {
    margin-bottom: calc(var(--gap-50) - 5px);
  }
}

.btnMain {
  color: var(--color-black);
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;  
  align-self: stretch;
}

.btnApplePay {
  background: var(--color-white);
  height: 56px;
  width: 140px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.checkoutBtns {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
}