.title {
  margin-top: 0;
  margin-bottom: var(--gap-70);
  font-size: 25px;
}

.subtitle {
  margin-top: var(--gap-50);
  margin-bottom: var(--gap-50);
  font-size: inherit;
  font-weight: 700;
  text-transform: uppercase;
}

.tableWrapper {
  margin-top: var(--gap-50);
  margin-bottom: var(--gap-50);
}

.divider {
  margin: var(--gap-50) 0;
  border-color: var(--grey);
}

.btnContainer {
  margin-top: var(--gap-50);
}

.btnContainer + .title {
  margin-top: var(--gap-70);
}

.btnContainer .btn:hover {
  text-decoration: none;
}

.date {
  color: var(--grey);
}

.text {
  margin: var(--gap-20) 0;
}
