.root {
  display: flex;
  align-items: center;
}

.cart {
  position: relative;
  display: flex;
  cursor: pointer;
}

.cartInner {
  overflow: hidden;
  width: 100%;
  position: absolute;
  bottom: 0;
  max-height: 100%;
}

.cartIcon {
  width: 24px;
  min-width: 24px;
  line-height: 0;

  @media (--desktop-up) {
    width: 36px;
    min-width: 36px;
  }
}

.cartBase {
  color: var(--primary);
}

.cartFull {
  position: absolute;
  bottom: 0;
  color: var(--primary);
}

.ship, .logo {
  font-weight: bold;
}

.ship b {
  color: var(--primary);
}

.cartInfo + .cartInfo {
  margin-left: var(--gap-10);

  @media (--tablet-up) {
    margin-left: var(--gap-20);
  }
}

.modal {
  padding: 0;
  z-index: -1;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &::before,
  &::after {
    content: none !important;
  }

  @media (--tablet-up) {
    width: var(--cart-side-md-width) !important;
  }

  @media (--desktop-up) {
    width: var(--cart-side-width) !important;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  padding: var(--gap-70) 0 0;

  @media (--desktop-up) {
    padding-top: var(--gap-120);
  }
}

.modalFooter {
  margin: auto 0 0;
  padding: var(--gap-25) var(--gutter-sm) var(--gap-50);
  padding-top: var(--gap-30);
  display: flex;
  align-items: center;
  flex-direction: column;
  background: var(--grey2);

  @media (--desktop-up) {
    padding: var(--gap-35) var(--gutter) var(--gap-50);
  }
}

.modalText {
  width: 75%;
  margin: 0 auto;
  font-size: 10px;
  font-weight: 700;
  text-align: center;
  color: var(--grey);
  margin-bottom: var(--gap-50);

  @media (--desktop-up) {
    font-size: 12px;
  }
}

.modalLink {
  color: inherit;
  text-decoration: underline;
}

.estimated {
  font-weight: bold;
  color: var(--grey);
  display: block;
  margin-left: auto;
}

.estimatedSelected {
  color: var(--primary);
}

.cartOverlayText {
  display: block;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  cursor: default;

  @media (--desktop-up) {
    font-size: 30px;
  }
}

.cartOverlayLink {
  color: var(--primary);
  text-decoration: underline;
  cursor: pointer;
}


.cartEmptyText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: calc(var(--gap-50) * -1);
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  cursor: default;

  @media (--desktop-up) {
    font-size: 24px;
  }
}

.cartEmptyLink {
  color: var(--primary);
  text-decoration: underline;
  cursor: pointer;
}