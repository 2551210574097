.wysiwyg-content {
  text-transform: none;
  color: var(--color-white);
  font-size: 16px;
  line-height: 1.38;
}

.wysiwyg-content a:hover {
  text-decoration: underline;
  word-wrap: break-word;
}

.wysiwyg-content p {
  margin-block-start: 1.38em;
  margin-block-end: 1.38em;
}

.wysiwyg-content h2 {
  font-size: 22px;
}

.wysiwyg-content hr {
  margin: var(--gap-50) 0;
  border-width: 1px;
  border-color: var(--grey8);
  border-style: solid;
}
