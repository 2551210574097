.image {
  width: 80px;
  min-width: 80px;
  height: 21px;
  line-height: 0;

  @media (--desktop-up) {
    width: 120px;
    min-width: 120px;
    height: 30px;
  }

  & svg {
    height: auto;
  }
}
