.ticker {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 15px;
  overflow: hidden;
  background: var(--primary);

  @media (--desktop-up) {
    height: 20px;
  }
}

.tickerItem {
  flex-shrink: 0;
  color: var(--color-black);
  font-size: 10px;
  line-height: 1;
  font-weight: 700;
  animation: ticker 60s linear infinite;
  transform: translate3d(0, 0, 0);

  @media (--desktop-up) {
    font-size: 15px;
  }
}

@keyframes ticker {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  98% {
    opacity: 1;
  }
  99% {
    transform: translateX(-300%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 0;
  }
}

.body {
  position: relative;
  width: 100%;
  background-color: var(--color-black);
  background-image: url('./footer.gif');
  background-size: 30px 30px;
  padding: var(--gap-80) 0;

  @media (--tablet-up) {
    padding: var(--gap-130) 0;
  }
}

.socialLinks {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.socialLinksItem {
  width: 100%;

  @media (--tablet-up) {
    width: fit-content;
  }
}

.socialLinksLink,
.socialLinksText {
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  color: var(--color-white);
  display: block;
  margin: var(--gap-20) 0;

  @media (--desktop-up) {
    font-size: 30px;
  }
}

.socialLinksLink:hover {
  color: var(--primary);
}

.socialLinksText {
  cursor: default;
}

.row {
  justify-content: center;
}

.down {
  position: relative;
  background-color: var(--grey2);
  display: flow-root;
  padding: var(--gutter-sm);
  font-size: 12px;
  z-index: 2;

  @media (--tablet-up) {
    padding: var(--gutter);
    font-size: 10px;
  }

  @media (--desktop-up) {
    font-size: 16px;
  }
}

.downP{
  width: fit-content;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;

  padding-top: var(--gutter-sm);

  @media (--tablet-up) {
    padding-top: var(--gutter);
  }
}

.downLink {
  display: inline-block;
  color: var(--grey3);
  margin: var(--gap-3) var(--gap-6);
  font-size: inherit;
  font-weight: bold;
  line-height: 1.2;

  @media (--desktop-up) {
    margin: var(--gap-3) var(--gap-16);
  }

  & span {
    font-size: inherit;
  }
}

.downLinkDash {
  border-bottom: 1px solid;
}

.cc {
  position: relative;
  background-color: var(--grey2);
  color: var(--grey4);
  text-align: center;
  width: 100%;
  font-size: 10px;
  font-weight: 700;
  padding: calc(var(--gutter-sm) * 1.5);
  z-index: 2;

  @media (--tablet-up) {
    padding: 0 0 calc(var(--gutter) * 1.25);
  }
  @media (--desktop-up) {
    font-size: 12px;
    padding: calc(var(--gutter) * 0.25) 0 calc(var(--gutter-sm) * 1.5);
  }
}

.cc a {
  color: var(--grey4);
  margin-left: var(--gap-8);
  text-decoration: none;
  cursor: pointer;
}

.cc a:hover {
  color: var(--primary);
  text-decoration: none;
}

.country {
  font-weight: bold;
  line-height: 1.25;
}
