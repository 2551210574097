@font-face {
    font-family: 'URW DIN';
    src: url('https://use.typekit.net/af/3bd84d/00000000000000003b9b0ee4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'URW DIN';
    src: url('https://use.typekit.net/af/359d41/00000000000000003b9b0eea/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3') format('woff2');
    font-weight: bold;
    font-style: normal;
}
