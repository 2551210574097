.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
}

.bottom {
  position: fixed;
  bottom: var(--gap-30);
  @media (--desktop-up) {
    bottom: var(--gap-50);
  }
}

.up {
  position: fixed;
  top: calc(var(--gutter-sm) * 1.25);

  @media (--desktop-up) {
    top: calc(var(--gutter) * 1.25);
  }
}

.side {
  display: flex;
  align-items: center;
  margin: 0 calc(-1 * var(--gap-10) / 2);
  @media (--desktop-up) {
    margin: 0 calc(-1 * var(--gap-20) / 2);
  }
}

.side > * {
  margin: 0 calc(var(--gap-10) / 2);
  @media (--desktop-up) {
    margin: 0 calc(var(--gap-20) / 2);
  }
}
