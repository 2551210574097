.root {
  display: flex;
  position: fixed;
  flex-direction: row;
  top: -1px;
  bottom: -1px;
  /* height: 100vh; */
  /* bottom: calc(var(--gap-100) * -1); */
  /* padding-bottom: var(--gap-100); fix hiding navbar on scroll */
  left: 0;
  width: 100%;
  z-index: -1;
}

.left {
  flex-direction: row-reverse;
}

.full .contentWrapper {
  width: 100%;
  max-width: 100%;
}

.contentWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--bg-color);

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 110px;
    width: 100%;
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%);
    pointer-events: none;
    z-index: 1;
  }

  &::after {
    top: auto;
    bottom: 0;
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%);
  }

  @media (--tablet-up) {
    min-width: var(--cart-side-md-width);
    width: 50%;
    max-width: 768px;
  }
}

.content {
  height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.overlay {
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;

  &.cart,
  &.cart-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 30px;
    background-color: rgba(28, 28, 28, 0.9);
    z-index: -2;
  }

  &.cart {
    display: none;

    @media (--tablet-up) {
      display: flex;
    }
  }
}

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 150px 0;
  text-align: center;

  @media (--tablet-up) {
    padding: 200px 0;
  }
}

.item,
.divider {
  font-size: 20px;
  margin: 5px 0;
  background: none;
  border: none;
  cursor: default;
  color: var(--color-white);

  @media (--desktop-up) {
    font-size: 30px;
  }
}

.item {
  font-weight: 700;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.itemCurrent {
  color: var(--primary);
}

.itemActive {
  color: var(--primary);
}

.modal {
  z-index: 99;
}

.close {
  display: inline-flex;
  cursor: pointer;
  line-height: 0;
}

.close svg {
  height: 20px;
  width: 20px;

  @media (--desktop-up) {
    height: 30px;
    width: 30px;
  }
}
