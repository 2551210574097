.simple-accordion + hr {
  margin: var(--gap-20) 0;
}

.simple-accordion > h2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: calc(var(--gap-20) * -1) 0;
  padding: var(--gap-25) 0;
  font-size: 20px;
  line-height: 1.2;
  cursor: pointer;
  transition: all .2s;
}

@media (--desktop-up) {
  .simple-accordion > h2 {
    margin: calc(var(--gap-20) * -1) 0;
    padding: var(--gap-50) 0;
  }
}

.simple-accordion > h2::after {
  content: '';
  background-image: url('../icons/plus.svg');
  width: 18px;
  height: 18px;
  margin: 4px;
  margin-left: var(--gap-20);
}

.simple-accordion > h2:hover {
  color: var(--primary);
}

.simple-accordion > div {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  height: auto;
  max-height: 0;
  overflow: hidden;
}

.simple-accordion.active > h2::after {
  background-image: url('../icons/minus.svg');
}

.simple-accordion.active > div {
  opacity: 1;
  height: auto;
  max-height: 5000px;
}
