.root {
  height: 100%;
  width: 100%;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  object-fit: cover;
}

.center {
  object-fit: contain;
}

.video::-webkit-media-controls {
  display: none;
}
