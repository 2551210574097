.wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  position: fixed;
  position: -webkit-sticky;
  position: sticky;
  z-index: 3;
  bottom: 0;
  right: 0;
  height: 0;
  float: right;
  padding-right: var(--gutter-sm);
  /* padding-bottom: calc(var(--gutter-sm) * 1.25); */
  transform: translateY(calc(var(--gutter-sm) * -1.25));
  overflow: visible;

  @media (--phone-medium-up) {
    /* padding-bottom: var(--gutter); */
    transform: translateY(calc(var(--gutter) * -1));
  }
  @media (--tablet-up) {
    /* padding-bottom: calc(var(--gutter-sm) * 1.25); */
    transform: translateY(calc(var(--gutter-sm) * -1.25));

  }
  @media (--desktop-up) {
    /* padding-bottom: calc(var(--gutter) * 1.25); */
    transform: translateY(calc(var(--gutter) * -1.25));
    padding-right: var(--gutter);
  }
}

.list {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: env(safe-area-inset-right);
}

/* .list:hover .item span {
  display: initial;
} */

.item {
  color: var(--color-white);
  text-decoration: none;
  margin: var(--gap-6) 0;
  line-height: .9;
  font-weight: bold;
  font-size: 13px;
  display: flex;
  justify-content: flex-end;
  min-width: 140px;
}

.item span {
  display: none;
}

.item::after {
  content: '';
  display: inline-flex;
  width: 8px;
  height: 8px;
  background-color: var(--color-white);
  margin-left: var(--gap-8);

  @media (--desktop-up) {
    width: 12px;
    height: 12px;
  }
}

.item:last-child::after {
  height: 16px;

  @media (--desktop-up) {
    height: 24px;
  }
}

.itemActive {
  color: var(--primary);
}

.itemActive::after {
  background-color: var(--primary);
}
