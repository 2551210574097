.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: calc(-0.5 * var(--gutter-sm));
  margin-left: calc(-0.5 * var(--gutter-sm));

  @media (--desktop-up) {
    margin-right: calc(-0.5 * var(--gutter));
    margin-left: calc(-0.5 * var(--gutter));
  }
}

.nomargin {
  margin: 0;
}
