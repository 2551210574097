.root {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 2;
}

.visible {
  background: var(--bg-color);
}

.title {
  object-fit: contain;
  object-position: center;
  z-index: 1;
  width: 100%;
  margin-bottom: var(--gap-20);
  @media (--tablet-up) {
    width: 50%;
  }
}

.btn {
  z-index: 1;
  width: 116px;
  @media (--desktop-up) {
    width: 140px;
  }
}

.bottom {
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 1;

  left: 0;
  bottom: calc(var(--gutter-sm) * 1.25);

  @media (--phone-medium-up) {
    bottom: var(--gutter);
  }
  @media (--tablet-up) {
    bottom: calc(var(--gutter-sm) * 1.25);
  }
  @media (--desktop-up) {
    bottom: calc(var(--gutter) * 1.25);
  }
}

.video {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 1;
  visibility: hidden;
  background: var(--color-black);
}

.videoVisible {
  visibility: inherit;
}

.content {
  position: fixed;
  left: 0;
  top: 50%;
  width: 100%;
  padding: var(--gutter-sm);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  z-index: 2;
  transform: translateY(-50%);

  @media (--tablet-up) {
    padding: var(--gutter);
  }
}

.contentVisible {
  visibility: inherit;
}

.fullVideoContainer {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--color-black);
}

:global(.root-cart-open) .video,
:global(.root-cart-open) .content,
:global(.root-cart-open) .fullVideoContainer {
  left: calc(var(--cart-side-width) / -2);
}

.fullVideo {
  width: 100%;
  height: 100%;
}
