.btns {
  position: relative;
  z-index: 1;
  display: flex;
  width: 430px;
  max-width: 100%;
  margin-bottom: var(--gutter);

  @media (--tablet-up) {
    margin-bottom: calc(var(--gutter-sm) * 1.25);
  }

  @media (--desktop-up) {
    margin-bottom: calc(var(--gutter) * 1.25);
  }
}

.content {
  position: relative;
  padding-top: var(--gap-50);
  padding-bottom: var(--gap-50);

  @media (--tablet-up) {
    padding-top: var(--gap-50);
    padding-bottom: var(--gap-70);
  }

  @media (--desktop-up) {
    padding-top: var(--gap-70);
    padding-bottom: var(--gap-80);
  }
}

.content::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: calc(var(--gutter-sm) * -1);
  right: calc(var(--gutter-sm) * -1);
  border-bottom: 1px solid var(--grey8);

  @media (--desktop-up) {
    left: calc(var(--gutter) * -1);
    right: calc(var(--gutter) * -1);
  }
}

.content :global(h3) {
  margin-top: var(--gap-30);
  margin-bottom: var(--gap-10);
  font-size: 16px;
  text-transform: uppercase;
}

.link {
  margin-right: var(--gap-20);
}

.linkActive {
  color: var(--primary);
}

.linkHidden {
  opacity: 0;
  visibility: hidden;
}

.linkClose {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: calc(var(--gutter-sm) + var(--gap-6));
  bottom: var(--gutter);
  padding: calc(var(--gap-8) + 1px);
  margin: calc(var(--gap-8) * -1);
  line-height: 1;
  cursor: pointer;
  z-index: 10;

  @media (--tablet-up) {
    right: var(--gutter-sm);
    bottom: calc(var(--gutter-sm) * 1.25);
  }

  @media (--desktop-up) {
    padding: var(--gap-12);
    right: var(--gutter);
    bottom: calc(var(--gutter) * 1.25);
  }
}

.linkClose svg {
  width: 12px;
}

:global(#product_info) {
  display: block;
}