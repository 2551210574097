.root {
  position: relative;
  display: flex;
  align-items: center;
  color: var(--color-white);
  text-decoration: none;
  padding-right: var(--gutter-sm);

  @media (--tablet-up) {
    padding-left: calc(var(--gutter-sm) / 2);
  }

  @media (--desktop-up) {
    padding-left: var(--gutter-sm);

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 5px solid var(--color-white);
      z-index: 0;
    }

    &:hover::after{
      content: '';
    }
  }
}

.link {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.imgWrapper {
  width: 120px;
  min-height: 120px;

  @media (--desktop-up) {
    width: 150px;
    min-height: 150px;
  }
}

.img {
  width: 100%;
}

.title {
  font-size: 16px;
  margin-bottom: var(--gap-3);

  @media (--desktop-up) {
    font-size: 20px;
  }
}

.body {
  font-weight: bold;
  margin: 0 var(--gap-10);
}

.feature {
  margin-right: var(--gap-10);
  color: var(--grey6);
  font-size: 10px;

  @media (--desktop-up) {
    font-size: 12px;
  }
}

.feature b {
  color: var(--primary);
}

.remove {
  position: relative;
  display: inline-flex;
  margin-left: auto;
  padding: var(--gap-6);
  color: #fff;
  cursor: pointer;
  z-index: 5;

  @media (--tablet-up) {
    margin-right: 0;
  }

  @media (--desktop-up) {
    margin-right: var(--gutter-sm);
  }
}

.remove svg {
  height: 12px;
  width: 12px;

  @media (--desktop-up) {
    height: 15px;
    width: 15px;
  }
}
