.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9;
}

.logo {
  width: 80px;
  min-width: 80px;
  line-height: 0;

  @media (--tablet-up) {
    width: 120px;
    min-width: 120px;
  }
}

.bottom {
  position: fixed;
  bottom: var(--gap-30);
  @media (--tablet-up) {
    bottom: var(--gap-50);
  }
}

.up {
  position: fixed;

  top: var(--gutter-sm);

  @media (--tablet-up) {
    top: var(--gutter);
  }
}
.side {
  display: flex;
  align-items: center;
}

.cart {
  position: relative;
  cursor: pointer;
}

.cartInner {
  overflow: hidden;
  width: 100%;
  position: absolute;
  bottom: 0;
  max-height: 100%;
}

.cartIcon {
  width: 24px;
  min-width: 24px;
  @media (--tablet-up) {
    width: 36px;
    min-width: 36px;
  }
}

.cartBase {
  color: var(--primary);
}

.cartFull {
  position: absolute;
  bottom: 0;
  color: var(--primary);
}

.ship, .logo {
  font-weight: bold;
  margin-right: var(--gap-10);
  @media (--tablet-up) {
    margin-right: var(--gap-20);
  }
}

.ship > * {
  margin-right: var(--gap-10);
}

.ship b {
  color: var(--primary);
}

.filters {
  font-weight: bold;
  display: none;
  @media (--tablet-up) {
    display: block;
  }
}

.filters > * {
  display: none;
}

.filters > *:first-child {
  display: initial;
}

.modal {
  padding: var(--gap-135) 0;
  padding-bottom: 0;
  z-index: -1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.modalBeforeFull {
  width: calc(100vw - 400px);
}

.modalEmpty {
  background: none;
}

.modalFooter {
  color: var(--grey2);
  margin: auto 0 0;
  padding: var(--gap-50) var(--gutter-sm);
  padding-top: var(--gap-30);
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (--tablet-up) {
    padding: var(--gap-50) var(--gutter);
  }
}

.modalText {
  width: 75%;
  margin: var(--gap-50) auto;
  font-size: 10px;
  text-align: center;
  color: var(--grey);
}

.estimated {
  color: var(--grey);
  display: block;
  margin-left: auto;
  margin-bottom: var(--gap-50);
  font-size: 14px;
  font-weight: 700;
}

.estimatedSelected {
  color: var(--primary);
}

.list {
  margin-bottom: var(--gap-25);

  @media (--desktop-up) {
    margin-bottom: var(--gap-40);
  }
}