.filters {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 34px;
  margin-top: -10px;
  margin-bottom: -10px;
  font-weight: bold;
}

.filters > * {
  display: none;
}

.filters > *:first-child {
  display: initial;
}
