.list {
    margin: 0;
    padding: 0;
}

.item {
    list-style-type: none;
    color: var(--grey);
    font-size: 10px;
    font-weight: 700;

    &:not(:last-child) {
        margin-bottom: var(--gap-6);
    }

    @media (--desktop-up) {
      font-size: 12px;
    }
}

.value {
    color: var(--primary);
}