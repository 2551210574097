.root {
  min-width: calc(320px - 2 * var(--gutter-sm));
  width: calc(100% - 2 * var(--gutter-sm));
  margin: 0 var(--gutter-sm);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);

  @media (--desktop-up) {
    width: calc(100% - 2 * var(--gutter));
    margin: 0 var(--gutter);
  }
}
