.base {
  margin: 0 calc(-1 * var(--gutter-sm)) var(--gap-80);

  @media (--desktop-up) {
    margin: 0 calc(-1 * var(--gutter)) var(--gap-150);
  }
}

.more {
  margin: 0 auto var(--gap-100);
  display: block;

  @media (--desktop-up) {
    margin: 0 auto var(--gap-150);
  }
}
