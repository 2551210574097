.swiper-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-pagination-bullet {
  border-radius: 0px;
  width: 6px;
  height: 6px;
  margin: 0 14px 0 0 !important;
  background: #fff;
  opacity: 1;

  @media (--desktop-up) {
    width: 8px;
    height: 8px;
    margin: 0 20px 0 0 !important;
  }
}

.swiper-pagination-bullet:last-child {
  margin: 0 !important;
  background-color: transparent;
  outline: none;
  width: 0;
  height: 0;

  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 6px solid #fff;

  @media (--desktop-up) {
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 8px solid #fff;
  }
}

.swiper-pagination-bullet-active {
  background-color: var(--primary);
  border-left-color:var(--primary) !important;
}

.swiper-pagination.swiper-pagination-bullets {
  bottom: var(--gap-20);
}

.swiper-slide-product {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}