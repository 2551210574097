.totalText {
  text-align: right;
}

tr.selected th,
tr.selected td {
  outline: 2px solid var(--primary);
  outline-offset: -1px;
}

.head {
  text-transform: uppercase;
  font-weight: 700;
}

.checkbox {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: -5px -10px;
  color: var(--grey);
}

.checkboxInput {
  position: absolute;
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 0;
  opacity: 0;
  visibility: hidden;
}

.checkboxInput:checked + .checkboxLabel {
  color: var(--color-white);
  border-color: var(--color-white);
}

.checkboxLabel {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.checkboxIcon svg {
  width: 10px;
  height: 13px;

  @media (--tablet-up) {
    width: 13px;
    height: 13px;
  }
}