.root {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url('./footer.gif'); */
  /* background-size: 30px 30px; */

  @media (--tablet-up) {
    height: 300px;
  }
}

.title {
  font-size: 20px;
  font-weight: bold;

  @media (--tablet-up) {
    font-size: 30px;
  }
}
