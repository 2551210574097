:root {
  --bg-color: #000;
  --color-white: #fff;
  --color-black: #000;

  --primary: #FF5E00;
  --grey:  #58595B;
  --grey2: #161616;
  --grey3: #808080;
  --grey4: #414042;
  --grey5: #7d7d82;
  --grey6: #6d6e71;
  --grey7: #e0e0e0;
  --grey8: #272727;
  --grey9: #D1D1D1;

  --gap-150: 150px;
  --gap-145: 145px;
  --gap-135: 135px;
  --gap-130: 130px;
  --gap-120: 120px;
  --gap-100: 100px;
  --gap-80: 80px;
  --gap-70: 70px;
  --gap-50: 50px;
  --gap-40: 40px;
  --gap-35: 35px;
  --gap-30: 30px;
  --gap-25: 25px;
  --gap-20: 20px;
  --gap-16: 16px;
  --gap-12: 12px;
  --gap-10: 10px;
  --gap-8: 8px;
  --gap-7: 7px;
  --gap-6: 6px;
  --gap-3: 3px;

  --gutter: 40px;
  --gutter-sm: 20px;

  --cart-side-width: 512px;
  --cart-side-md-width: 375px;
}

body {
  min-height: 100vh;
  min-width: 320px;
  margin: 0;
  padding: 0;
  font-family: 'URW DIN', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
  color: var(--color-white);
  font-size: 12px;
  text-transform: uppercase;
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (--desktop-up) {
    font-size: 16px;
  }
}

body::-webkit-scrollbar {
  display: none;
}

html {
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*, *:before, *:after {
  box-sizing: border-box;
}

h3 {
  font-size: 30px;
  margin: 0;
}

h4 {
  font-size: 20px;
}

select {
  appearance: none;
  text-transform: uppercase;
  font-size: inherit;
  font-weight: inherit;
  color: var(--primary);
  background: none;
  border: none;
  font-family: inherit;
  width: fit-content;
}

a {
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
}

a.underline {
  text-decoration: underline;
}

button {
  text-transform: uppercase;
}

.segment {
  color: var(--primary);
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
}

.table-wrapper {
  width: calc(100% + 2 * var(--gutter-sm));
  margin: 0 calc(-1 * var(--gutter-sm));
  padding: 0 var(--gutter-sm);
  overflow-x: auto;
  scrollbar-width: none;
}

.table-wrapper::-webkit-scrollbar {
  display: none;
}

.table-td-title {
  color: var(--primary);
  font-weight: 700;
}

.text-uppercase {
  text-transform: uppercase;
}

table {
  width: 100%;
  min-width: 600px;
  border-collapse: collapse;
}

tbody {
  /* overflow: hidden; */
}

td,
th {
  position: relative;
  padding: 5px 10px;
  border: 2px solid var(--grey8);
  text-align: left;
  vertical-align: top;
}

th {
  font-weight: 700;
}

tr td {
  color: var(--grey9);
}

@media (--tablet-up) {
  td,
  th {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media (--desktop-up) {
  td,
  th {
    padding-top: 13px;
    padding-bottom: 13px;
  }
}

.table-hover {
  overflow: hidden;
}

.table-hover td:hover {
  background-color: var(--grey8);
  cursor: default;
}

.table-hover td:hover::before,
.table-hover td:hover::after {
  content: '';
  position: absolute;
  background-color: var(--grey8);
  opacity: 0.4;
  z-index: -1;
}

/* Row */
.table-hover td:hover::before {
  height: 100%;
  left: -5000px;
  top: 0;
  width: 10000px;
}

/* Column */
.table-hover td:hover::after {
  height: 10000px;
  left: 0;
  top: -5000px;
  width: 100%;
}

@media (--extra-large-desktop-up) {
  .root-cart-open {
    width: calc(100% - var(--cart-side-width));
  }

  .root-cart-open .cart-popup {
    width: var(--cart-side-width);
    right: 0;
    left: auto;
  }

  .root-cart-open .cart-overlay {
    display: none;
  }
}

#products::before {
  content: '';
  display: block;
  margin-top: calc(var(--gap-70) * -1);
  padding-top: var(--gap-70);
  visibility: hidden;

  @media (--desktop-up) {
    margin-top: calc(var(--gap-130) * -1);
    padding-top: var(--gap-130);
  }
}