.root {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  padding: 150px 0 var(--gap-100);

  &::before,
  &::after {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 110px;
    width: 100%;
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%);
    pointer-events: none;
    z-index: 1;
  }

  &::after {
    top: auto;
    bottom: 0;
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%);
  }

  @media (--desktop-up) {
    padding-top: 160px;
  }
}

.title {
  margin-top: 0;
  margin-bottom: var(--gap-70);
  font-size: 25px;
}

.divider {
  margin: var(--gap-50) 0;
  border-color: var(--grey);
}

.support {
  color: var(--grey);
  margin-top: var(--gap-20);
  text-transform: none;
  font-size: 16px;
}

.sendingError {
  margin-left: 20px;
}

.btnContainerMt {
  margin-top: 20px;
}
