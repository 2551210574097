.root {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
}

:global(.root-cart-open) .root {
  left: calc(var(--cart-side-width) / -2);
}

.mainVideo {
  min-height: 100vh;
}

.mainImages {
  min-height: 500px;
}

.video {
  height: 100vh;
  width: 100%;
  position: initial;

  @media (--desktop-up) {
    width: 100vw;
  }
}

.videoBody {
  position: absolute;
  visibility: visible;
  top: 50%;
}

:global(.root-cart-open) .productVideoContainer {
  left: unset;
}

.relative {
  position: relative;
  height: 100vh;
  width: 100%;
}

.img {
  position: initial;
  width: 100vw;
  min-height: 50px;
  object-fit: cover;
  display: block;
}

.footer {
  display: flex;
  position: sticky;
  flex-direction: column-reverse;
  bottom: 0;
  height: 0;

  @media (--desktop-up) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.block {
  border: 0;

  @media (--desktop-up) {
    width: 100%;
    min-width: 430px;
    width: initial;
    border: 6px solid #fff;
    padding: var(--gap-30);
    margin-right: var(--gap-30);
    margin-bottom: var(--gap-30);
  }
}

.title {
  font-size: 25px;
}

.price {
  font-weight: bold;
  color: var(--primary);
  font-size: 15px;
}

.sizes {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--gap-20);
}

.size {
  background-color: var(--color-white);
  color: var(--color-black);
  cursor: pointer;
  width: 26px;
  height: 26px;
  margin-right: var(--gap-10);
  display: flex;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  line-height: 0;

  font-weight: bold;
}

.sizeDisable {
  background-color: transparent;
  color: var(--grey5);
  border: 2px solid var(--grey5);
  cursor: default;
}

.sizeActive {
  background-color: var(--primary);
  color: var(--color-white);
}

.info {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-bottom: var(--gutter-sm);

  @media (--desktop-up) {
    flex-direction: row;
    margin-bottom: calc(var(--gutter) * 1.25);
    width: initial;
  }
}

.buy {
  position: absolute;
  right: 0;
  margin-bottom: calc(var(--gutter-sm) * -0.4);

  @media (--desktop-up) {
    margin-bottom: 0;
  }
}
