.label {
    font-size: 10px;
    font-weight: 700;
    color: var(--grey6);
  
    @media (--desktop-up) {
      font-size: 12px;
    }
  }
  
  .root {
    display: block;
    margin-bottom: 23px;
  
    @media (--tablet-up) {
      margin-bottom: 18px;
    }
  
    @media (--desktop-up) {
      margin-bottom: 31px;
    }
  }
  
  .input {
    color: var(--color-white);
    font-weight: bold;
    border: none;
    appearance: none;
    background: none;
    padding: 1px 2px;
    width: 100%;
    font-size: 16px;
    line-height: 18px;
    border-bottom: 1px solid var(--color-white);
  
    @media (--desktop-up) {
      font-size: 20px;
      line-height: 25px;
    }
  
    &:not(:placeholder-shown) {
      text-transform: none;
    }
    
    &.select option {
      color: var(--color-black);
      text-transform: none;
      border-bottom: 1px solid var(--color-white);
    }
  }
  
  .inputError {
    border-color: var(--primary);
  }
  
  .labelError {
    color: var(--primary);
  }
  
  .labelErrorText {
    margin-left: var(--gap-3);
    font-weight: 400;
  }
  
  .error {
    font-size: 10px;
    color: var(--primary);
    margin-top: var(--gap-6);
    display: inline-block;
  }
  
  .selectWrapper {
    position: relative;
    text-transform: none;
  
    &::after {
      content: '';
      width: 12px;
      height: 12px;
      position: absolute;
      right: 6px;
      bottom: 2.5px;
      display: block;
      background-image: url('../../assets/icons/arrow.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;
    }
  }
  
  .countryInput {
    color: var(--grey6);
    display: flex;
    justify-self: start;
    padding-left: 0;
  
    &:not(:placeholder-shown) {
      border-color: var(--grey6);
    }
  }
  
  .optionPlaceholder {
    text-transform: inherit;
    color: var(--grey6);
  }

  select:required:invalid {
    color: var(--grey6);
    border-bottom: 1px solid var(--grey6);
  }
  
  option {
    color: var(--color-black);
  }