
.searchForm {
  max-width: 335px;
}

.searchInputRoot {
  margin-bottom: var(--gap-10);
}

.searchInputLabel {
  color: var(--color-white);
  font-size: 16px;

  &::after {
    content: '*';
  }
}

.searchInput {
  margin-top: var(--gap-3);
  padding: var(--gap-8) var(--gap-10);
  background: var(--color-white);
  color: var(--color-black);
  font-weight: 400;

  &::placeholder {
    color: var(--grey7);
  }

  &:-ms-input-placeholder {
    color: var(--grey7);
  }

  &::-ms-input-placeholder {
    color: var(--grey7);
  }
}

.input {
  font-weight: 400;
  font-size: 16px;
}

.btn {
  width: 100%;
  min-width: 200px;
  text-transform: uppercase;

  @media (--tablet-up) {
    width: auto;
  }
}

.btnContainer {
  margin-top: var(--gap-50);
}

.btnContainer .btn:hover {
  text-decoration: none;
}
